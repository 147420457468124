import LegalLayout from "../../../components/legal/LegalLayout"
import React from "react"
import source from "raw-loader!../../../constants/legal/abuse/how-we-handle.md"

export default function HowWeHandlePage() {
  return (
    <LegalLayout
      description="Guiding principles and process for investigating abuse reports"
      source={source}
      title="How we handle abusive usage"
    />
  )
}
